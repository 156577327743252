import React from 'react';
import i18n from 'i18next';
import Helmet from 'react-helmet';
import { isExperienceEditorActive, dataApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from './dataFetcher';
import config from './temp/config';
import Layout from './Layout';
import NotFound from './NotFound';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

class RouteHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: true,
      defaultLanguage: config.defaultLanguage,
      cookies: props.cookies,
    };

    const routeData = this.extractRouteData();

    // route data from react-router - if route was resolved, it's not a 404
    if (routeData !== null) {
      this.state.notFound = false;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (routeData && (!routeData.sitecore || !routeData.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (routeData && routeData.context && routeData.context.language) {
      this.state.defaultLanguage = routeData.context.language;
    }

    this.componentIsMounted = false;
    this.languageIsChanging = false;

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    const routeData = this.extractRouteData();

    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!routeData || this.props.ssrRenderComplete) {
      this.updateRouteData();
    }

    // once we initialize the route handler, we've "used up" the SSR data,	
    // if it existed, so we want to clear it now that it's in react state.	
    // future route changes that might destroy/remount this component should ignore any SSR data.	
    // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice	
    // (once to find GraphQL queries that need to run, the second time to refresh the view with	
    // GraphQL query results)	
    // We test for SSR by checking for Node-specific process.env variable.	
    if (typeof window !== "undefined" && !this.props.ssrRenderComplete && this.props.setSsrRenderComplete) {
      this.props.setSsrRenderComplete(true);
    }

    this.componentIsMounted = true;
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  extractRouteData = () => {
    if (!this.props.sitecoreContext) return null;

    const { route, ...context } = this.props.sitecoreContext;

    return  {
      sitecore: {
        route,
        context
      }
    }
  }

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateRouteData() {
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    const language = this.props.route.match.params.lang || this.state.defaultLanguage;

    // get the route data for the new route
    getRouteData(sitecoreRoutePath, language).then((routeData) => {
      if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
        // set the sitecore context data and push the new route
        this.props.updateSitecoreContext({
          route: routeData.sitecore.route,
          itemId: routeData.sitecore.route.itemId,
          ...routeData.sitecore.context,
        });
        this.setState({ notFound: false });
      } else {
        // get 404 route data instead
        if(routeData == 'canceled') return;
        getRouteData('/404', language).then((routeData) => {
          if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
            this.props.updateSitecoreContext({
              route: routeData.sitecore.route,
              itemId: routeData.sitecore.route.itemId,
              ...routeData.sitecore.context,
            });
            this.setState({ routeData, notFound: false });
          } else {
            // okay, I give up
            this.setState({ notFound: true }, () =>
              this.props.updateSitecoreContext(routeData.sitecore.context)
            )
          }
        });
      }
    });
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage = this.props.route.match.params.lang || this.state.defaultLanguage;

    if (i18n.language !== newLanguage) {
      this.languageIsChanging = true;

      i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false;

        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate();
        }
      });
    }
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;

    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    
    
    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isExperienceEditorActive()) {
      if (typeof window !== 'undefined') {
        window.location.assign(newRoute);
      }
      return;
    }

    this.updateLanguage();
    this.updateRouteData();
  }

  render() {

    const { notFound } = this.state;
    const routeData = this.extractRouteData();

    try {

      // no route data for the current route in Sitecore - show not found component.
      // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
      // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
      if (notFound && routeData) {
        return (
          <>
            <Helmet>
              <title>{i18n.t('Page not found')}</title>
            </Helmet>
            <NotFound context={routeData.sitecore && routeData.sitecore.context} />
          </>
        );
      }

      // Don't render anything if the route data or dictionary data is not fully loaded yet.
      // This is a good place for a "Loading" component, if one is needed.
      if (!routeData || this.languageIsChanging) {
        return null;
      }

      // Render the app's root structural layout
      return <Layout cookies={this.state.cookies} route={routeData.sitecore.route} />;
    }
    catch (error) {
      // this allows to differentiate server vs client rendering
      if (typeof window !== 'undefined') {
        // used to avoid side-effects via setState()
        console.error('Error inside', this.getComponentName(), 'is', error);
      }
      return this.renderErrorView();
    }
  }

}

function renderErrorView() {
  return (
    <span>Error 500</span>
  );
}

export default withSitecoreContext({ updatable: true })(RouteHandler)

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language) {
  let returnUrl = "/";
  if(typeof window !== 'undefined' && window?.location?.search.length > 0)
  {
      returnUrl = window?.location?.search.split("=").pop();
  }

  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: { sc_lang: language, sc_apikey: config.sitecoreApiKey, ReturnUrl: returnUrl },
    fetcher: dataFetcher,
  };
  const coveoRoutes = ["/search", "/news-and-events/my-news", "/news-and-events/news"];
  /** This seems to be a workaround for a bug in how Coveo search works, since SPA client side nav is not working properly
  Example: Go to search, click on a result, click back. The search results are not displayed, without this that is, so use array of pages that use Coveo, since we're adding news search to the site, third one is for local dev purposes */
  console.log(`route`, route)
  const isCoveoPage = () => coveoRoutes.some((coveoRoute) => coveoRoute.toLowerCase() === route);
  const isJssConnected = window.location.hostname === "localhost"
  if(typeof window !== 'undefined' && isCoveoPage() && !isJssConnected)
  {
    window.location.reload();
  }
  let response = dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (error.response && error.response.status === 404 && error.response.data) {
      return error.response.data;
    } else if (error.response && error.response.status === 403 || error.response.status === 401) {
      window.location.reload();
      return "canceled"
    }

    console.error('Route data fetch error', error, error.response);

    return null;
  });
  //ScrollTo top of page after fetchRoute completed 
  response.then(()=>{
    if (typeof window !== 'undefined') {
      if(window.location.hash.length == 0) {
        window.scrollTo(0, 0);
      }
    }
  });
  return response;
}
