import React from 'react';
import Helmet from 'react-helmet';

const PageMetadata = ({ fields }) => {
    let robotsParams = [];

    if (fields?.noindex?.value) robotsParams.push('noindex');
    if (fields?.nofollow?.value) robotsParams.push('nofollow');
    let homeTitle = fields?.omitDefaultTitle?.value ? '' : '| University of Chicago'

    return (

        <Helmet titleTemplate={`%s ${homeTitle}`}>
            <title>{fields.browserTitle?.value || fields.pageTitle?.value}</title>
            <meta prefix="og: http://ogp.me/ns#" property="og:title" content={fields.browserTitle?.value || fields.pageTitle?.value} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta prefix="og: http://ogp.me/ns#" property="og:url" content={typeof window !== 'undefined' ? window.location.href : '/'} />

            {
                fields.socialMediaImage?.value?.src &&
                <meta property="og:image" content={fields.socialMediaImage.value.src} />
            }
            {
                fields.MetaDescription?.value &&
                <meta name="description" content={fields.MetaDescription.value} />
            }
            {
                fields.MetaDescription?.value &&
                <meta prefix="og: http://ogp.me/ns#" property="og:description" content={fields.MetaDescription.value} />
            }
            {
                fields.MetaKeywords &&
                <meta name="keywords" content={fields.MetaKeywords.value} />
            }
            {
                fields.Attributes && fields.Attributes.value
                    .split('&').map(x => x.split('=').map(decodeURIComponent))
                    .map((x, i) => (
                        <meta name={x[0]} content={x[1]} key={i} />
                    ))
            }
            {
                robotsParams.length > 0 &&
                <meta name="robots" content={robotsParams.join(', ')} />
            }

        </Helmet>);
};

export { PageMetadata };