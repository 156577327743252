import React from 'react';
import { Placeholder, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import './scss/index.scss';
import { PageMetadata } from './react/PageMetadata';

const Layout = ({ route, cookies, sitecoreContext }) => {
  const siteName =  sitecoreContext.site.name ? sitecoreContext.site.name.toLowerCase() : ''; //get the site name
  const [,...levels] = global?.location?.pathname ? global?.location?.pathname.split('/') : ''; //get the pathname of it exists and put the pathname into an array called levels

  // for this special fix, we have to target the president's site and its specific pages
  let tclValue = 'false'
  let apmValue = 'false'
  
  if(siteName == 'president'){
    //history page
    if( levels[1]?.toLowerCase() == 'history' && !Boolean(levels[2]?.toLowerCase())){
      tclValue = 'true'
    }
    else if(levels[0]?.toLowerCase() == "about-the-office"){
      tclValue = 'true'
    }
    else if((((levels[1]?.toLowerCase() == 'history') && Boolean(levels[2]?.toLowerCase()))) ||
    (levels[0]?.toLowerCase() == 'leadership')){
      apmValue = 'true';
      tclValue = 'false'
    }
    if((((levels[1]?.toLowerCase() == 'officers'))) &&
    (levels[0]?.toLowerCase() == 'leadership') ){
      apmValue = 'false';
      tclValue = 'false'
    }
    if(((levels[1]?.toLowerCase() == 'officers')) &&
    (levels[0]?.toLowerCase() == 'leadership') && Boolean(levels[2]?.toLowerCase())){
      apmValue = 'false';
      tclValue = 'true'
    }
    if((((levels[1]?.toLowerCase() == 'deans'))) &&
    (levels[0]?.toLowerCase() == 'leadership') ){
      apmValue = 'false';
      tclValue = 'true'
    }
    if((((levels[1]?.toLowerCase() == 'laboratory-directors'))) &&
    (levels[0]?.toLowerCase() == 'leadership') ){
      apmValue = 'false';
      tclValue = 'true'
    }
    if((((levels[1]?.toLowerCase() == 'org-chart'))) &&
    (levels[0]?.toLowerCase() == 'leadership') ){
      apmValue = 'false';
      tclValue = 'true'
    }
  }


  const dataprops = {
    apm: apmValue,
    tcl: tclValue
   };


  return(
    <React.Fragment>
      <Helmet>
        {/* Gotham: */}
        <link rel="preconnect" href="https://cdn.fonts.net"/>
        <link href="https://cdn.fonts.net/kit/eb5a73c0-a7d9-4c2b-ac4c-d227a441e27f/eb5a73c0-a7d9-4c2b-ac4c-d227a441e27f.css" rel="stylesheet" />
        {/* <link rel="stylesheet" type="text/css" href="https://cloud.typography.com/6526092/6336412/css/fonts.css" /> */}
        {/* Garamond: */}
        <link rel="stylesheet" type="text/css" href="https://use.typekit.net/haa5fqb.css" />
        {/* Fontawesome: */}
        <script src="https://kit.fontawesome.com/8a6f4b518c.js" crossorigin="anonymous"></script>
        <style type="text/css">{`
          .coveo-first-loading-animation {
            display: none;
          }
      `}</style>
      </Helmet>
  
      <PageMetadata {...route} />
  
      <Placeholder name="jss-body-top" rendering={route} />
      <main data-sitename={siteName} {...dataprops}>
        <div className="jss-main">
          <Placeholder name="jss-main" rendering={route} />
        </div>
      </main>
  
      <Placeholder name="jss-body-bottom" rendering={route} />
    </React.Fragment>
  )
};

export default withSitecoreContext()(Layout);